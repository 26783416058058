@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

body {
  font-family: 'Press Start 2P', monospace;
  color: #41403e;
}

input,
button {
  font-family: 'Press Start 2P', monospace;
  font-weight: 700;
}
